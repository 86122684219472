.description-wrap{
    & ul{
        padding-left: 20px;
        list-style-type: circle;
        & li{
            margin-bottom: 15px;
        }
    }
    & h3,h2,h1{
        font-weight: normal;
    }
}
body{
    font-family: 'Open Sans', sans-serif;
}